"use server";

import { getRequestEvent } from "solid-js/web";
import { getAllGifts, getGiftById, getUserProfile } from "../apis/server_apis";
import { GiftCardDetails } from "../types/pluto";
import { UserProfile } from "../types/user";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";
import { APIError } from "~/utils/fetch";

export type PlutoProfileRouteData = {
  paidGifts?: GiftCardDetails[];
  notPaidGifts?: GiftCardDetails[];
  userProfile?: UserProfile;
};

export const getPlutoProfileRouteData =
  async (): Promise<PlutoProfileRouteData> => {
    const requestEvent = getRequestEvent();
    let userProfile: UserProfile | undefined;
    let notPaidGifts: GiftCardDetails[] | undefined;
    let paidGifts: GiftCardDetails[] | undefined;

    let sid = getCookie(Cookie.SessionId);

    if (!sid) {
      throw redirect("/pluto/login");
    }

    try {
      const gifts = await getAllGifts({
        throwAuthError: true,
      });

      const detailedGifts = await Promise.all(
        gifts.map((gift) =>
          getGiftById(gift.id, {
            throwAuthError: true,
          })
        )
      );

      userProfile = await getUserProfile(
        {},
        {
          throwAuthError: true,
        }
      );

      notPaidGifts = detailedGifts
        .filter((conversation) => conversation.status === "COMPLETED")
        .filter(
          (gift) =>
            gift.paymentStatus === "NOT_PAID" ||
            gift.paymentStatus === "PENDING"
        );

      paidGifts = detailedGifts
        .filter((conversation) => conversation.status === "COMPLETED")
        .filter((gift) => gift.paymentStatus === "PAID");
    } catch (error) {
      if (error instanceof APIError && error.code === 401) {
        throw redirect("/pluto/login");
      }
      throw error;
    }

    return { paidGifts, notPaidGifts, userProfile };
  };
